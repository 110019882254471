
/* CSS Calendario */

.DateRangePickerInput__withBorder {
    border-radius: 5px;
    border: none;
    border-bottom: 1px solid rgba(129, 129, 129, 0.867);
    margin-top: 9px;
    font-family: Cambay,sans-serif;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: #ff5656;
    border: 1px solid #ff5656;
    color: #fff;
}

.CalendarDay__selected_span {
    background: #ff5656;
    border: 1px solid #ff5656;
    color: #fff;
    font-family: Cambay,sans-serif;
}

.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
    background: #ff5656;
    border: 1px solid #ff5656;
    color: #fff;
}

.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
    background: #ff5656;
    border: 1px solid #ff5656;
    color: #fff;
}