.remarcar-paper {
    width: 100%;
}
.div-table{
    margin: 20px;
}
.input-paper{
    /* display: 'flex'; */
    /* flex-wrap: 'wrap'; */
    /* padding: '-5px'; */
    /* justify-content: 'center'; */
    margin: 20px;
}
.input {
    width: '250px';
}
.div-formControl{
    /* width: 500px; */
    padding: 10px;
}

.div-inputs{
    display:flex;
}

.div-formInputs{
    width: 40%;
    padding: 10px;
}

.div-button{
    align-self: center;
}
