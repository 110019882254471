.custom-container{
    background-color: #242424;
        width: 100%;
    height: 100%;
}

.custom .node circle {
	fill: #F3F3FF;
	stroke: #2593B8;
	stroke-width: 1.5px;
}
.custom .node text {
    width: 300px;
	font-size: 11px;
	background-color: #444;
	fill: #F4F4F4;
	text-shadow: 0 1px 4px black;
}

.custom .node {
	cursor: pointer;
}

.custom path.link {
	fill: none;
	stroke: #2593B8;
	stroke-width: 1.5px;
}