.DateRangePickerInput__withBorder {
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #03305d;
    margin-top: 9px;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: #03305d;
    border: 1px solid #03305d;
    color: #fff;
}

.CalendarDay__selected_span {
    background: #3f51b5;
    border: 1px solid #3f51b5;
    color: #fff;
}

.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
    background: #3f51b5bb;
    border: 1px solid #3f51b56b;
    color: #fff;
}

.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
    background: #03305d;
    border: 1px solid #03305d;
    color: #fff;
}