.remarcar-container {
    flex-wrap: nowrap;
    justify-content: center;
    display: inline-block;
}
.remarcar-input {
    display: flex;
    justify-content:space-evenly
}
.remarcar-table {
    margin: 12px;
    /* border-radius: 5; */
    padding: 12px;
    /* width: '100%'; */
}

.remarcar-paper {
    /* display: flex;
    flex-wrap: wrap;
    padding: -5px;
    justify-content: center; */
    width: 100%;
    color: #03305d !important;
}
.remarcar-header {
    padding: 5px;
    text-align: center;
}
.remarcar-forms {
    display: flex;
    justify-content: space-around;
}
.remarcar-buttons {
    display: flex;
    /*justify-content: flex-end !important;*/
    justify-content: space-between;
}
.remarcar-button {
    margin: 10px !important;
    height: 36px
}
.remarcar-buttonfb {
    position: relative;
    align-self: flex-end;
}
.remarcar-fabProgress {
    color: #008000 !important;
    position: absolute;
    top: -3px;
    left: -3px;
    z-index: 1;
}
.remarcar-grid {
    margin: 12px;
    border-radius: 5px;
    padding: 12px;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
    height: 500px;
    overflow-y: auto;
}
.remarcar-header-list {
    color: white !important;
    background: #3f51b5 !important;
    padding: 5px;
    text-align: center;
}
.remarcar-list {
    border: 1px solid #03305d !important;
    color: #03305d !important;
    padding: 6px;
    overflow: hidden;
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: smaller;
}
.remarcar-icon {
    text-align: center;
    font-size: 30px;
}
.textarea-obs{
    margin:10px;
    width: 400px;
    border-radius: 4px;
    margin-left: 20px;
}
.button-obs{
    margin-top: 10px;
}
.button-tell{
    margin: 10px;
    display: flex;
}