.title {
    width: 100%;
    padding: 10px;
    background-color: #023575;
    margin-bottom: 10px;
    margin-top: 10px;

}

.title-atendimento {
    text-align: center;
    margin-top: 15px;
}

.buttonTimer{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10PX;

  }
.title-pedido {
    display: relative;
    margin-top: 15px;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    
}

.subtitle-pedido {
    margin: 2rem;
    background-color: #023575;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    
}


.button {
    text-align: right;
    border-radius: 10px;
    margin: 10px;
    padding: 5px;
}

.sub-title {
    margin: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 1px solid #ddd;
}

.sub-title Button {
    background-color: #023575;
    color: #fff;
}

.sub-title Button:hover {
    background-color: #fff;
    color: #000;
    
}

.exames {
    display: flex;
    justify-content: space-between;
    
}

/* .exame {
    padding: 10px;
    border: 1px solid #ddd;
} */