@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
// @import '~@fullcalendar/timegrid/main.css';
// @import '~@fullcalendar/bootstrap/main.css';
.demo-app {
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
  text-transform: capitalize;
}

.demo-app-top {
  margin: 0 0 3em;
}

.demo-app-calendar {
  margin: auto;
}

.fc-view-container {
  position: relative;
  z-index: 0;
}
// .fc-day-grid-event .fc-h-event .fc-event .fc-start .fc-end{
//   z-index: -1 !important;
// }
.button-att{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.button-voltar{
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
}

.fabProgress {
  color: #4caf50 !important;
  position: absolute;
  top: -4px;
  left: -4px;
  z-index: 1;
}

.wrapper {
  margin: 8;
  position: relative;
  align-self: flex-end;
}

.buttonSuccess {
  background-color: #4caf50;
  &:hover {
    background-color: #4caf50;
  };
}

.buttonTimer{
  display: flex;
  justify-content: space-between;
}