
.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    cursor: pointer;
}

p {
    font-weight: bold;
    color: #d9d9d9;
    
}


.drop {
    margin: 5px;
    background-color: #0078F0;
    color: #fff;
    width: 100;
    height: 25px;
    border-radius: 5px;
    border: none;
    font-stretch: semi-condensed;
    
}

.container .drop {
    background-color: #0078F0;
    color: #fff;
    width: 100;
    height: 25px;
    border-radius: 5px;
    border: none;
    
}

.dropzone {
    width: 100%;

}

