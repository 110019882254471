* {
    box-sizing: border-box;
  }
  .colorus{
    background:#C0C0C0;
  }
  .App {
    text-align: center;
    width: inherit
  }
  .Paper{
      height: 56px;
  }
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
  }
  
  .App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
  }
  
  .App-title {
    font-size: 1.5em;
  }
  
  .App-intro {
    font-size: large;
  }
  
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
  }
  /* 
  @media screen and (min-width: 1367px) and (max-width: 1920px){
    .lista-itens {
      width: 1208px !important;
    }
  }
  @media screen and (min-width: 100px) and (max-width: 1366px){
    .lista-itens {
      width: 637px !important;
    }
  } */
  .menu-item {
    width: 100%;
    padding: 0 40px;
    margin: 5px 10px;
    user-select: none;
    cursor: pointer;
    border: 1px transparent solid;
  }
  .menu-item-wrapper {
    border: 1px transparent solid;
  }
  /* .menu-item-wrapper.active {
    border: 1px blue solid;
  } */
  .menu-item.active {
    border: 1px green solid;
  }
  
  .scroll-menu-arrow {
    padding: 20px;
    cursor: pointer;
  }
  
  /* .scroll-menu-arrow--disabled {
    visibility: hidden;
  } */
  /*
  .grid-template-area{
    grid-template-columns: 30% 70%;
    grid-template-rows: 100% 100%;
    grid-template-areas:
      "sidenav content "
      ;
  }
  
  .item {

    margin: 5px;
    text-align: center;
    font-size: 1.5em;
    
  }
  .content{
    grid-area: content;
    align-content: end;

  }
  .sidenav{
    grid-area: sidenav;
    align-content: end;

  }
  .grid {
    display: grid;
    max-width: 100%;
    max-height: 100%;
  }*/
  .container{
    display:flex;
    width: 100%;
    justify-content: space-between;
    padding: 15px;
  }
  .containerCalendario{
    
    align-self: center;
  }
  .calendario {
    font-family: Montserrat, 'Segoe UI', Tahoma, sans-serif !important;
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
    border-radius: 4px;
    max-width: 100%;
    font: inherit;
    border: 0 !important;
    overflow: hidden;
  }
