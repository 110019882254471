.observacao {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.observacoes {
    font-family: Cambay,sans-serif;
    height: 90px;
    border: 1px solid #ddd;
    resize: none;
    border-radius: 5px;
    margin-right: 5px;
    margin-left: 10px;
}

.observacaos {
    font-family: Cambay,sans-serif;
    resize: none;
    border: 1px solid #ddd;
    width: 300px;
    height: 50px;
    margin-right: 5px;
    margin-left: 10px;
    border-radius: 5px;
}

.iconbutton {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.iconbutton .tableicon {

    background-color: #ff5656;
    color: #fff;
    margin: 10px;
    box-shadow: 1px 4px 1px #ddd;
    transition: 0.3s;
}

.iconbutton .tableicon:hover {
    background-color: #c23737;
    color: #fff;
}