.anexo-section-header {
  color: #0D3C61;
  text-align: center;
}

.anexo-buttons {
  text-align: center;
}

.anexo-content {
  margin: 20px;
  font-size: 12px;
}

.anexo-container {
  padding: 10px;
  font-size: smaller;
  width: 100%;
  margin-top: 10px;
}

.perguntas-container {
  padding: 15px 15px 20px 15px;
  flex-wrap: nowrap;
  justify-content: center;
  display: inline-block;
}

.perguntas-paper {
  font-size: smaller;
  width: 100%;
}

.perguntas-header {
  background-color: #023575;
  color: #fff;
  border-radius: 10px;
  padding: 2px;
  /* margin: -15px !important; */
  text-align: center;
}

.perguntas-colPerguntas {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.perguntas-perguntas {
  border: 1px solid #03305d !important;
  border-radius: 4px;
  padding: 5px;
  width: 70%;
}

.perguntas-link {
  text-decoration: none;
  color: white;
}

.perguntas-colPaciente {
  display: flex;
  justify-content: space-between;
  margin-bottom: -20px;
}

.perguntas-respostas {
  display: flex;
  justify-content: flex-end;
}

.perguntas-resposta {
  padding-right: 10px;
}

.perguntas-divisor {
  padding: 5px;
}

.perguntas-orientacao {
  border: 1px solid #03305d !important;
  border-radius: 4px;
  padding: 6px;
  height: 130px;
  overflow-y: scroll;
  width: 100%;
  resize: none
}

.perguntas-grid {
  padding: 8px;
}

.perguntas-buttons {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.perguntas-button {
  margin-left: 12px !important;
}

.imageList {
  width: 500px;
  height: 450px;
}